import { render, staticRenderFns } from "./Addsupplier.vue?vue&type=template&id=453a14e4&scoped=true&"
import script from "./Addsupplier.vue?vue&type=script&lang=js&"
export * from "./Addsupplier.vue?vue&type=script&lang=js&"
import style0 from "./Addsupplier.vue?vue&type=style&index=0&id=453a14e4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "453a14e4",
  null
  
)

export default component.exports